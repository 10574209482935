import React, { useState } from "react"

import { v4 as uuidV4} from 'uuid';

import { getDatabase, ref, set } from "firebase/database";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDi5b17xC1vFr78F7M2lHd6WdhHMUVW0_k",
    authDomain: "database-4e237.firebaseapp.com",
    databaseURL: "https://database-4e237-default-rtdb.firebaseio.com",
    projectId: "database-4e237",
    storageBucket: "database-4e237.appspot.com",
    messagingSenderId: "877831380980",
    appId: "1:877831380980:web:f7791c49932f7b6b4423a1",
    measurementId: "G-B34FET33C5"
  };
const app = initializeApp(firebaseConfig);

function writeUserData(name, email, pass) {
  const db = getDatabase();
    if (name != "" && email != "" && pass != "") {
    set(ref(db, 'users/' + String(uuidV4())), {
        username: name,
        email: email,
        password : pass
    });
    }
}

export const Register = (props) => {
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [name, setName] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        writeUserData(name, email, pass)
    }
    return (
        <div className="auth-form-container">
            <h2>Register</h2>
        <form className="register-form" onSubmit={handleSubmit}>
            <label htmlFor="name">Full name</label>
            <input value={name} onChange={(e) => setName(e.target.value)} name="name" id="full Name" placeholder="fullName" type="name" minLength={2} maxLength={30}></input>
            <label htmlfor="email">email</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeHolder="youremail@gmail.com" id="email" name="email" minLength={4} maxLength={100}></input>
            <label htmlfor="password">password</label>
            <input value={pass} onChange={(e) => setPass(e.target.value)} type="password" placeHolder="********" id="password" name="password" minLength="6" maxLength={200}></input>
            <button type="submit">Log In</button>
        </form>
        <button className="link-btn" onClick={() => props.onFormSwitch('login')} >Already have an account? Login here.</button>
        </div>
    )
}